<script lang="ts" setup>
import { useAppStore } from '~/stores/app'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { getUserDevice, moveTo } from '~/common/Common'
import { DEVICE_TYPE, WindowMainType } from '~/enum/enum'
import { openWeb } from '~/plugins/message'

const app = useAppStore()
const { currentMenu, menu } = storeToRefs(app)
//@ts-ignore
const bottomNav = ref(null)
let menus: [] = []

const isShuttle = () => {
  if (menu.value && menu.value.SHUTTLE_TYPE === 'Y') {
    return true
  } else {
    return false
  }
}

const moveBusOrDaily = () => {
  if (isShuttle()) {
    const router = useRouter()
    // console.log(router)
    if (router.currentRoute.value.path === '/bus') {
      location.reload()
    } else if (router.currentRoute.value.path === '/bus/route') {
      router.replace('/bus')
    } else {
      //router.replace('/bus')
      moveTo('/bus')
    }
  } else {
    if (app.userInfo?.WINDOW_MAIN_TYPE !== WindowMainType.MATA) {
      moveTo('/daily')
    } else {
      // moveTo('/payment')
      goOnlinePay()
    }
  }
}

const getMypageUrl = () => {
  // 마이페이지 모바일, 피씨 구분
  const device = getUserDevice(app.appType)
  let deviceValue = ''
  if (device !== DEVICE_TYPE.T_WEB) {
    deviceValue = '&DEVICE=M'
  } else {
    deviceValue = '&DEVICE=P'
  }
  let url =
    'https://api.dybwindow.com/other_contents/mypage?UM_IDX=' +
    app.userInfo?.UM_IDX
  let link = url + '&jwttoken=' + app.userInfo?.ACCESS_TOKEN + deviceValue
  openWeb(link)
}
const goOnlinePay = () => {
  const device = getUserDevice(app.appType)
  let deviceValue = ''
  if (device !== DEVICE_TYPE.T_WEB) {
    deviceValue = '&DEVICE=M'
  } else {
    deviceValue = '&DEVICE=P'
  }
  const payLink =
    app.menu?.menu_cat4.find((item) => item.MENU_SEQ === '15')?.MENU_PAGE_URL +
    '&UM_IDX=' +
    app.userInfo?.UM_IDX +
    '&jwttoken=' +
    app.userInfo?.ACCESS_TOKEN +
    deviceValue
  openWeb(payLink)
}

onMounted(async () => {
  //@ts-ignore
  menus = bottomNav.value.querySelectorAll('ul > li > a')
  app.setCurrentMenu(menus, currentMenu.value as number)
})
watch(
  () => currentMenu.value,
  (newVal) => {
    app.setCurrentMenu(menus, newVal)
  },
)
</script>
<template>
  <nav id="bottomNav" ref="bottomNav">
    <ul>
      <li @click="moveTo('/menu')"><a class="navMoreM active">전체 메뉴</a></li>
      <li @click="moveTo('/grade')"><a class="navRecordM">성적 기록</a></li>
      <li @click="moveTo('/main')"><a class="navDybM">홈</a></li>
      <!-- 셔틀버스 / 수업일지 / 수강료 결제 -->
      <li @click="moveBusOrDaily()">
        <a
          :class="[
            isShuttle()
              ? 'navShuttleM'
              : app.userInfo?.WINDOW_MAIN_TYPE !== WindowMainType.MATA
                ? 'navDailyM'
                : 'navPayM',
          ]"
        >
          {{
            isShuttle()
              ? '셔틀 버스'
              : app.userInfo?.WINDOW_MAIN_TYPE !== WindowMainType.MATA
                ? '수업 일지'
                : '온라인 결제'
          }}
        </a>
      </li>
      <!-- 클래스 / 마이페이지 -->
      <li
        @click="
          app.userInfo?.WINDOW_MAIN_TYPE !== WindowMainType.MATA
            ? moveTo('/class')
            : getMypageUrl()
        "
      >
        <a
          v-if="app.userInfo?.WINDOW_MAIN_TYPE !== WindowMainType.MATA"
          class="navClassM"
          >클래스</a
        >
        <a v-else class="navMypageM cursor-pointer">마이페이지</a>
      </li>
    </ul>
  </nav>
</template>
